import { Table } from "@eds/vanilla";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { getcreateHazardIdentification } from "../../api/HazardID.api";
import HazardIDIdentification from "./HazardIDIdentification";
import { useSelector } from "react-redux";

export default function HazardIDTable({ getListHazardIdenti, callRefresh }) {
    const [getData, setData] = useState();
    const [getHazardIdenti, setHazardIdenti] = useState([]);
    const [getHazardIdentiPopUp, setHazardIdentiPopUp] = useState(false);
    const [isLoading, setIsLoading] = useState({
        getHazardIdentification: false,
    });
    const [errMsg, setError] = useState({
        getHazardIdentification: "",
    });
    const { headersList } = useSelector((state) => state.envVariables);

    const handleViewMore = (request_id) => {
        setHazardIdentiPopUp(true);
        getcreateHazardIdentification(headersList, setIsLoading, setError, request_id, setHazardIdenti);
    }

    const handleTableData = () => {
        let products = [];
        getListHazardIdenti.map(i => {
            products.push({
                request_ID: i.id,
                request_date_time: i.created,
                customer: i.customer,
                site_ID: i.site_id,
                site_survey_archive: i.archive.filename,
                status: i.status === "BATCH_COMPLETED" ? "Completed" : "In-progress",
                user: i.user_employee_id,
                actions: i.id,
            });
        })
        return products;
    };

    useEffect(() => {
        const tableDOM = document.querySelector("#HazardIDTable");
        if (_.isEmpty(getData)) {
            const table = new Table(tableDOM, {
                data: handleTableData(),
                columns: [
                    {
                        key: "request_ID",
                        title: "ID",
                        sort: "none",
                    },
                    {
                        key: "request_date_time",
                        title: "Date",
                        sort: "none",
                        cellClass: "text-wrap",
                    },
                    {
                        key: "customer",
                        title: "Customer",
                        sort: "none",
                    },
                    {
                        key: "site_ID",
                        title: "Site ID",
                        sort: "none",
                    },
                    {
                        key: "site_survey_archive",
                        title: "Survey Archive",
                        sort: "none",
                    },
                    {
                        key: "status",
                        title: "Status",
                        sort: "none",
                        onCreatedCell: (td, data) => {
                            td.innerHTML = `<kbd class="tag ${data === "Completed" ? "green" : "orange"}">${data}</kbd>`
                        },
                    },
                    {
                        key: "user",
                        title: "User",
                        sort: "none",
                    },
                    {
                        key: "actions",
                        title: "Actions",
                        sort: "none",
                        onCreatedCell: (td, data) => {
                            td.innerHTML = `<button class="btn primary icon-btn viewMore"><i class="icon icon-eye"></i> View</button>`
                            td.querySelector("button.viewMore").addEventListener(
                                "click",
                                () => {
                                    handleViewMore(data);
                                }
                            );
                        },
                    }
                ],
                sortable: true,
                expandable: false,

            });
            table.init();
            setData(table);
        } else {
            getData.update(handleTableData());
        }
    }, [getListHazardIdenti]);

    return (
        <>
        {getHazardIdentiPopUp && (
            <HazardIDIdentification 
            setHazardIdentiPopUp={setHazardIdentiPopUp}
            getHazardIdenti={getHazardIdenti}
            setHazardIdenti={setHazardIdenti}
            isLoading={isLoading}
            errMsg={errMsg}
            setError={setError}
            />
        )}
            <div className="table-top">
                <div className="table-top-left">
                    <div className="table-info"></div>
                </div>
                <div className="table-top-right">
                    <div className="table-buttons">
                        <button className="btn" id="exportData" onClick={() => callRefresh()}>Refresh</button>
                    </div>
                    <div className="table-search">
                        <input type="text" className="with-icon" placeholder="Search for..." />
                        <div className="suffix icon-inside"><i className="icon icon-search"></i>
                        </div>
                    </div>
                </div>
            </div>
            <table id="HazardIDTable" className="table dashed compact stickyHeader horizonatalScroll"></table>
            <div className="table-bottom">
                <div className="pagination-group">
                    <ul className="pagination"></ul>
                    <div className="actions">
                        <label className="left">Go to</label>
                        <input type="number" min="1" />
                        <label className="left">Show</label>
                        <div className="select" data-type="single">
                            <button className="btn current-options">5</button>
                            <div className="options-list">
                                <div className="item active">5</div>
                                <div className="item">10</div>
                                <div className="item">15</div>
                                <div className="item">All</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}