import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { getUploadFolder, getcreateHazardIdentification } from "../../api/HazardID.api";
import { Select } from '@eds/vanilla';
import Loading from "../../components/Loading/Loading";
import AlertEDS from "../../components/AlertWarn/AlertEDS";

export default function HazardIDIdentification({ setHazardIdentiPopUp, getHazardIdenti, setHazardIdenti, isLoading, errMsg, setError }) {
    const ImageToken = useSelector((state) => state.loggedInUser.user.profilePicture);
    const SasToken = ImageToken.indexOf("?");
    const querystring = ImageToken.substring(SasToken);

    const onCloseHandler = () => {
        setHazardIdenti([]);
        setError((val) => ({ ...val, getListHazardIdentification: "" }));
        setHazardIdentiPopUp(false);
    };

    return (
        <>
            <div className="dialog HazardIDIdentiffication show modal-md" data-trigger="#HazardIDIdentiffication" data-type="simple">
                <div className="content">
                    <div className="top">
                        <div className="title">Hazard Identification Request ID - {getHazardIdenti.id} </div>
                        <div className="right">
                            <button className="btn-icon" data-close="true" onClick={() => onCloseHandler()}>
                                <i className="icon icon-cross"></i>
                            </button>
                        </div>
                    </div>
                    <div className="body">
                        <div className="tile sm-12 md-12 lg-12 xl-12 createHazardEle">
                            {isLoading.getHazardIdentification ? (
                                <div className="centerLoader">
                                    <Loading />
                                </div>
                            ) :
                                (<> {errMsg.getHazardIdentification !== "Success" ?
                                    (<>
                                        <AlertEDS errMsg={errMsg.getHazardIdentification} status_code={201} close={false} />
                                    </>)
                                    :
                                    (<>
                                        {!_.isEmpty(getHazardIdenti) && (
                                            <table className="table dashed hazardsImtigationTable">
                                                <thead>
                                                    <tr>
                                                        <th>Uploaded Images</th>
                                                        <th>Hazard Identified</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getHazardIdenti?.archive?.files.map((i, k) => {
                                                        return (
                                                            <tr key={k}>
                                                                <td><img src={i.url + querystring} alt="hazards images" /> </td>
                                                                <td>{getHazardIdenti?.result?.output.map((j, idx) => {
                                                                    if (j.custom_id === i.id) {
                                                                        return (
                                                                            <span key={idx}>{j.message_content}</span>
                                                                        )
                                                                    }
                                                                })}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    <tr></tr>
                                                </tbody>
                                            </table>
                                        )}
                                    </>
                                    )}
                                </>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}