import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { getListHazardIdentification } from "../../api/HazardID.api";
import Loading from "../../components/Loading/Loading";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import HazardIDTable from "./HazardIDTable";
import HazardIDCreate from "./HazardIDCreate";

export default function HazardID() {
    const [getListHazardIdenti, setListHazardIdenti] = useState([]);
    const [isLoading, setIsLoading] = useState({
        getListHazardIdentification: false
    });
    const [errMsg, setError] = useState({
        getListHazardIdentification: ""
    });
    const [getCreateHazard, setCreateHazard] = useState(false);
    const { headersList } = useSelector((state) => state.envVariables);

    useEffect(() => {
        getListHazardIdentification(headersList, setIsLoading, setError, setListHazardIdenti);
    }, []);

    const callRefresh = async () => {
        await getListHazardIdentification(headersList, setIsLoading, setError, setListHazardIdenti);
    }

    return (
        <div className="HazardID">
            <div className="tile sm-12 md-12 lg-12 xl-12">
                <div className="header">
                    <div className="left">
                        <div className="title">Hazard Indentification</div>
                    </div>
                    <div className="right">
                        <div className="actionItem" onClick={() => setCreateHazard(true)}>
                            <span className="tooltip pointer">
                                <i className="icon icon-plus text-xl"></i>
                                <span className="message left blue">Add New Request</span>
                            </span>
                        </div>
                    </div>
                </div>

                <div className="content">
                    <div className="column">
                        {isLoading.getListHazardIdentification ? (
                            <Loading />
                        ) : (
                            <>
                                {errMsg.getListHazardIdentification !== "Success" ? (
                                    <AlertEDS errMsg={errMsg.getListHazardIdentification} status_code={201} close={false} />
                                ) : (
                                    <HazardIDTable
                                        getListHazardIdenti={getListHazardIdenti}
                                        callRefresh={callRefresh}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="header">
                    <div className="left">
                        <div className="disclaimer">
                            <span className="disclaimer-text" style={{ whiteSpace: "nowrap", marginLeft: "8px" }}>Disclaimer:</span>
                            <span style={{ color: "#888", alignItems: "center" }}>
                                {" "}
                                This feature is offered for experimentation purposes only. It
                                should not be used to draw decisive conclusions about the
                                environment.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {getCreateHazard && (
                <HazardIDCreate 
                setCreateHazard={setCreateHazard}
                callRefresh={callRefresh} 
                />
            )}
        </div>
    )
}